import React, { Component } from 'react'

class Logo extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="206" height="21" viewBox="0 0 206 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M202.395 17.5547H196.483V9.73589H202.419L204.489 11.6899V6.39949L202.419 8.35626H196.483V1.9143H202.373L204.948 4.51417V0.534668H191.654L193.817 3.11255V16.3592L191.654 18.9344H201.246L202.579 20.0639L205.846 16.1531L204.489 15.0263L202.395 17.5547Z" fill={color}/>
        <path d="M186.801 8.42474C185.1 7.80363 183.443 7.46009 182.016 6.65485C180.936 6.01175 180.29 4.99764 180.29 4.01101C180.29 2.63137 181.464 1.75742 183.05 1.75742C183.923 1.75742 184.913 2.01026 185.856 2.56267C187.004 3.2305 187.741 4.17315 188.247 5.16253L189.764 4.426L188.021 0.234877L186.457 0.902708C185.674 0.465732 184.707 0.212891 183.695 0.212891C180.752 0.212891 178.405 2.39777 178.405 5.11306C178.405 7.66621 180.131 9.16127 182.362 9.9885C184.042 10.6096 185.699 10.9999 187.032 11.7584C187.996 12.3108 188.505 13.2535 188.505 14.2428C188.505 16.0155 187.01 17.3264 184.962 17.3264C182.34 17.3264 179.765 15.3037 178.935 12.8192L177.971 12.9814L178.592 19.2612L180.293 17.8128C181.398 18.6648 182.799 19.1485 184.316 19.1485C187.675 19.1485 190.343 16.6173 190.343 13.4431C190.343 10.8212 189.011 9.25746 186.801 8.43023"fill={color} />
        <path d="M171.575 3.11255V15.3012C170.495 16.1971 168.906 16.7495 167.111 16.7495C166.191 16.7495 165.317 16.5186 164.58 16.1504C163.338 15.5512 162.417 14.5399 162.417 12.814V0.534668H157.586L159.749 3.1098V12.748C159.749 15.4633 160.922 17.2332 162.739 18.2226C163.706 18.7282 164.855 19.0278 166.097 19.0278C168.376 19.0278 170.352 17.879 171.572 16.1284V18.9344H174.93L176.079 17.5547H174.238V0.534668H169.406L171.569 3.1098L171.575 3.11255Z" fill={color}/>
        <path d="M148.113 0.212891C145.376 0.212891 142.847 1.50183 141.143 3.63999C139.764 5.38789 138.98 7.73492 138.98 9.9885C138.98 15.0701 142.798 19.2584 147.813 19.2584C152.829 19.2584 156.968 14.82 156.968 9.48282C156.968 4.42325 153.151 0.212891 148.113 0.212891V0.212891ZM149.218 17.0021C147.816 17.0021 146.343 16.5431 145.032 15.6912C142.594 14.1054 140.915 11.2527 140.915 8.42199C140.915 6.60538 141.605 4.92618 142.963 3.77465C144.021 2.87872 145.332 2.46373 146.711 2.46373C148.184 2.46373 149.748 2.96941 151.128 3.9368C153.359 5.50057 155.014 8.23785 155.014 11.0438C155.014 14.4242 152.576 17.0021 149.218 17.0021V17.0021Z" fill={color}/>
        <path d="M137.789 0.534668H130.797L132.957 3.11255V8.72453H124.496V3.11255L126.656 0.534668H119.664L121.827 3.11255V16.3592L119.664 18.9344H126.656L124.496 16.3592V10.1042H132.957V16.3592L130.797 18.9344H137.789L135.626 16.3592V3.11255L137.789 0.534668Z" fill={color}/>
        <path d="M105.955 0.534668L108.118 3.11255V14.7048L96.7103 1.43335L97.4688 0.534668H92.2031L94.3633 3.11255V16.3592L92.2031 18.9344H96.8944L98.0432 17.5547H95.7429V4.44546L108.118 18.9344H109.498V0.534668H105.955Z" fill={color}/>
        <path d="M82.611 0.212891C79.8738 0.212891 77.3426 1.50183 75.6414 3.63999C74.2618 5.38789 73.4785 7.73492 73.4785 9.9885C73.4785 15.0701 77.2959 19.2584 82.3115 19.2584C87.3271 19.2584 91.466 14.82 91.466 9.48282C91.466 4.42325 87.6486 0.212891 82.611 0.212891ZM83.7158 17.0021C82.3115 17.0021 80.8411 16.5431 79.5302 15.6912C77.0925 14.1054 75.4133 11.2527 75.4133 8.42199C75.4133 6.60538 76.1031 4.92618 77.4608 3.77465C78.5189 2.87872 79.8298 2.46373 81.2094 2.46373C82.6825 2.46373 84.2463 2.96941 85.6259 3.9368C87.8575 5.50057 89.512 8.23785 89.512 11.0438C89.512 14.4242 87.0742 17.0021 83.7158 17.0021V17.0021Z" fill={color}/>
        <path d="M69.2938 2.71955C67.8455 1.24647 66.1195 0.534668 62.7392 0.534668H52.9883L55.1512 3.1098V16.3592L52.9883 18.9344H60.3262C63.6846 18.9344 66.169 18.0824 67.8702 16.3812C70.2612 13.9902 71.4814 11.0221 71.4814 8.26282C71.4814 6.16863 70.7669 4.19262 69.2965 2.71955H69.2938ZM61.7278 17.7856C60.2795 17.7856 58.9438 17.3019 57.817 16.4967V2.60412C58.9905 2.02973 60.3014 1.68345 61.7278 1.68345C65.7073 1.68345 68.8568 5.24796 68.8568 9.73315C68.7194 14.2183 65.7045 17.7828 61.7278 17.7828V17.7856Z" fill={color}/>
        <path d="M46.7532 0.534668L48.9133 3.11255V14.7048L37.5052 1.43335L38.2637 0.534668H32.998L35.1609 3.11255V16.3592L32.998 18.9344H37.6894L38.8409 17.5547H36.5406V4.44546L48.9133 18.9344H50.2929V0.534668H46.7532Z" fill={color}/>
        <path d="M23.4079 0.212891C20.6706 0.212891 18.1395 1.50183 16.4383 3.63999C15.0586 5.38789 14.2754 7.73492 14.2754 9.9885C14.2754 15.0701 18.0927 19.2584 23.1083 19.2584C28.1239 19.2584 32.2629 14.82 32.2629 9.48282C32.2629 4.42325 28.4455 0.212891 23.4079 0.212891ZM24.5127 17.0021C23.1111 17.0021 21.638 16.5431 20.3271 15.6912C17.8894 14.1054 16.2102 11.2527 16.2102 8.42199C16.2102 6.60538 16.9 4.92618 18.2576 3.77465C19.3157 2.87872 20.6267 2.46373 22.0063 2.46373C23.4794 2.46373 25.0431 2.96941 26.4228 3.9368C28.6544 5.50057 30.3088 8.23785 30.3088 11.0438C30.3088 14.4242 27.8711 17.0021 24.5127 17.0021V17.0021Z" fill={color}/>
      <path d="M10.3889 17.3266H4.98576V3.11255L7.14591 0.534668H0.154297L2.31719 3.11255V16.3592L0.154297 18.9344H9.05596L10.3889 20.0639L13.6566 16.1531L12.2989 15.0263L10.3889 17.3266Z" fill={color}/>
      </svg>

    )
  }
}

class LogoLarge extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="1104" height="108" viewBox="0 0 1104 108" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1085.47 93.8074H1053.75V51.8418H1085.61L1096.71 62.3295V33.9345L1085.61 44.4369H1053.75V9.86138H1085.36L1099.18 23.8155V2.45654H1027.83L1039.44 16.2927V87.3909L1027.83 101.212H1079.31L1086.46 107.275L1104 86.2846L1096.71 80.2368L1085.47 93.8074Z" fill={color}/>
        <path d="M1001.78 44.8016C992.646 41.4679 983.751 39.6241 976.096 35.3021C970.299 31.8505 966.832 26.4075 966.832 21.112C966.832 13.7071 973.131 9.01644 981.642 9.01644C986.333 9.01644 991.643 10.3735 996.702 13.3384C1002.87 16.9228 1006.82 21.9823 1009.54 27.2925L1017.68 23.3393L1008.33 0.844568L999.933 4.42898C995.729 2.08362 990.537 0.726562 985.108 0.726562C969.311 0.726562 956.713 12.4533 956.713 27.027C956.713 40.7304 965.977 48.7547 977.954 53.1947C986.967 56.5283 995.862 58.6229 1003.02 62.6941C1008.19 65.659 1010.92 70.7185 1010.92 76.0287C1010.92 85.5429 1002.9 92.579 991.909 92.579C977.836 92.579 964.015 81.7225 959.56 68.3879L954.383 69.2582L957.716 102.963L966.847 95.1898C972.777 99.7625 980.3 102.359 988.442 102.359C1006.47 102.359 1020.79 88.7733 1020.79 71.7363C1020.79 57.6641 1013.64 49.271 1001.78 44.8311" fill={color}/>
        <path d="M920.057 16.2927V81.7119C914.26 86.5206 905.735 89.4855 896.102 89.4855C891.161 89.4855 886.47 88.2464 882.517 86.2698C875.85 83.0542 870.908 77.6259 870.908 68.3625V2.45654H844.977L856.585 16.2779V68.0085C856.585 82.5822 862.884 92.0816 872.634 97.3918C877.826 100.106 883.992 101.714 890.659 101.714C902.888 101.714 913.493 95.548 920.043 86.1518V101.212H938.068L944.234 93.8074H934.351V2.45654H908.419L920.028 16.2779L920.057 16.2927Z" fill="#F9F5EF"/>
        <path d="M794.13 0.726562C779.438 0.726562 765.867 7.64463 756.722 19.1206C749.317 28.5021 745.113 41.0991 745.113 53.1947C745.113 80.4687 765.602 102.949 792.522 102.949C819.442 102.949 841.656 79.1264 841.656 50.4806C841.656 23.3246 821.168 0.726562 794.13 0.726562V0.726562ZM800.06 90.8384C792.537 90.8384 784.63 88.375 777.594 83.8023C764.51 75.2912 755.498 59.98 755.498 44.7868C755.498 35.0366 759.2 26.024 766.487 19.8434C772.166 15.0347 779.202 12.8074 786.607 12.8074C794.513 12.8074 802.906 15.5215 810.311 20.7137C822.289 29.1068 831.169 43.7985 831.169 58.8589C831.169 77.0023 818.085 90.8384 800.06 90.8384V90.8384Z" fill={color}/>
        <path d="M738.708 2.45654H701.183L712.777 16.2927V46.4135H667.359V16.2927L678.953 2.45654H641.428L653.037 16.2927V87.3909L641.428 101.212H678.953L667.359 87.3909V53.8184H712.777V87.3909L701.183 101.212H738.708L727.1 87.3909V16.2927L738.708 2.45654Z" fill={color}/>
        <path d="M567.861 2.45654L579.47 16.2927V78.511L518.24 7.28001L522.311 2.45654H494.049L505.643 16.2927V87.3909L494.049 101.212H519.228L525.394 93.8074H513.048V23.4467L579.47 101.212H586.875V2.45654H567.861Z" fill={color}/>
        <path d="M442.567 0.726562C427.876 0.726562 414.29 7.64463 405.16 19.1206C397.755 28.5021 393.551 41.0991 393.551 53.1947C393.551 80.4687 414.039 102.949 440.959 102.949C467.879 102.949 490.094 79.1264 490.094 50.4806C490.094 23.3246 469.605 0.726562 442.567 0.726562ZM448.497 90.8384C440.959 90.8384 433.068 88.375 426.032 83.8023C412.948 75.2912 403.935 59.98 403.935 44.7868C403.935 35.0366 407.638 26.024 414.925 19.8434C420.604 15.0347 427.64 12.8074 435.044 12.8074C442.951 12.8074 451.344 15.5215 458.749 20.7137C470.726 29.1068 479.606 43.7985 479.606 58.8589C479.606 77.0023 466.522 90.8384 448.497 90.8384V90.8384Z" fill={color}/>
        <path d="M371.078 14.1833C363.305 6.27697 354.041 2.45654 335.898 2.45654H283.562L295.171 16.2779V87.3909L283.562 101.212H322.947C340.972 101.212 354.307 96.6396 363.437 87.5089C376.27 74.6758 382.82 58.7451 382.82 43.9354C382.82 32.6954 378.985 22.0897 371.093 14.1833H371.078ZM330.47 95.0465C322.696 95.0465 315.527 92.4504 309.479 88.1284V13.5638C315.778 10.4809 322.814 8.62232 330.47 8.62232C351.829 8.62232 368.733 27.7539 368.733 51.827C367.995 75.9001 351.814 95.0317 330.47 95.0317V95.0465Z" fill="#F9F5EF"/>
        <path d="M250.108 2.45654L261.702 16.2927V78.511L200.472 7.28001L204.544 2.45654H176.281L187.89 16.2927V87.3909L176.281 101.212H201.461L207.641 93.8074H195.295V23.4467L261.702 101.212H269.107V2.45654H250.108Z" fill={color}/>
        <path d="M124.809 0.726562C110.118 0.726562 96.5324 7.64463 87.4017 19.1206C79.9969 28.5021 75.793 41.0991 75.793 53.1947C75.793 80.4687 96.2816 102.949 123.202 102.949C150.122 102.949 172.336 79.1264 172.336 50.4806C172.336 23.3246 151.847 0.726562 124.809 0.726562ZM130.739 90.8384C123.216 90.8384 115.31 88.375 108.274 83.8023C95.1901 75.2912 86.1774 59.98 86.1774 44.7868C86.1774 35.0366 89.8799 26.024 97.1667 19.8434C102.846 15.0347 109.882 12.8074 117.287 12.8074C125.193 12.8074 133.586 15.5215 140.991 20.7137C152.968 29.1068 161.848 43.7985 161.848 58.8589C161.848 77.0023 148.765 90.8384 130.739 90.8384V90.8384Z" fill={color}/>
        <path d="M54.9315 92.5831H25.9317V16.2927L37.5257 2.45654H0L11.6088 16.2927V87.3909L0 101.212H47.7774L54.9315 107.275L72.47 86.2846L65.1832 80.2368L54.9315 92.5831Z" fill={color}/>
      </svg>

    )
  }
}


class LogoMark extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="252" height="250" viewBox="0 0 252 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M0 0.67041L11.6119 14.5006V85.666L0 99.4962H47.8058L54.9812 105.54L72.5236 84.5569L65.235 78.4906L54.9812 90.8495H25.9401V14.5006L37.552 0.67041H0Z" fill={color} />
          <path d="M223.546 45.3299C219.698 45.3299 216.167 44.0849 213.179 42.0025V6.03498C216.303 4.54105 219.766 3.65827 223.546 3.65827C234.117 3.65827 242.469 12.8935 242.469 24.5054C242.107 36.1173 234.094 45.3525 223.546 45.3525V45.3299ZM243.624 6.32924C239.776 2.52651 235.203 0.67041 226.217 0.67041H200.322L206.049 7.34783V41.6403L200.322 48.3177H219.789C228.707 48.3177 235.294 46.1221 239.821 41.7082C246.181 35.5062 249.396 27.8328 249.396 20.68C249.396 15.2702 247.494 10.132 243.601 6.32924" fill={color}/>
          <path d="M33.5003 205.112L38.7517 211.382V239.609L10.9781 207.285L12.8342 205.112H0L5.27403 211.382V243.638L0 249.93H11.4308L14.215 246.558H8.62406V214.642L38.7517 249.93H42.1243V205.112H33.5003Z" fill={color}/>
          <path d="M216.098 157.736L227.054 170.638V198.751H184.205V170.638L195.138 157.736H159.736L170.692 170.638V237.005L159.736 249.93H195.138L184.205 237.005V205.677H227.054V237.005L216.098 249.93H251.5L240.567 237.005V170.638L251.5 157.736H216.098Z"  fill={color}/>
        </g>
      </svg>

    )
  }
}

class Mail extends Component {
  render() {
    let { color } = this.props
    return (

      <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.0402 36.3213C27.6402 36.3213 35.4202 28.4413 35.4202 18.7213C35.4202 9.00133 27.6402 1.11133 18.0402 1.11133C8.44016 1.11133 0.660156 8.99133 0.660156 18.7113C0.660156 28.4313 8.44016 36.3113 18.0402 36.3113V36.3213Z" stroke={color} strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.20898 11.6816V24.7616L15.649 18.3616" stroke={color} strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.379 19.1616L27.699 11.6816H8.20898L16.529 19.1616C17.359 19.9016 18.549 19.9016 19.379 19.1616V19.1616Z" stroke={color} strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.2695 18.3616L27.7095 24.7616V11.6816" stroke={color} strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.20898 24.7617H27.709" stroke={color} strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  }
}

class WhatsApp extends Component {
  render() {
    let { color } = this.props
    return (
    <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.1393 29.9558L8.1193 24.4858C6.2893 22.4058 5.2793 19.7658 5.2793 17.0258C5.2793 15.4558 5.5993 13.9358 6.2293 12.5058C6.8393 11.1258 7.7093 9.89582 8.8193 8.83582C9.9193 7.77582 11.2093 6.94582 12.6393 6.36582C14.1193 5.76582 15.6893 5.46582 17.3093 5.46582C18.9293 5.46582 20.4993 5.76582 21.9793 6.36582C23.4093 6.94582 24.6993 7.77582 25.7993 8.83582C26.9093 9.89582 27.7793 11.1358 28.3893 12.5058C29.0193 13.9358 29.3393 15.4558 29.3393 17.0258C29.3393 18.5958 29.0193 20.1158 28.3893 21.5458C27.7793 22.9258 26.9093 24.1558 25.7993 25.2158C24.6993 26.2758 23.4093 27.1058 21.9793 27.6858C20.4993 28.2858 18.9293 28.5858 17.3093 28.5858C15.5793 28.5858 13.9193 28.2458 12.3593 27.5658L7.1393 29.9458V29.9558Z" stroke={color} strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.37992 0.425781H27.2599C31.0999 0.425781 34.2199 3.61578 34.2199 7.54578V27.8858C34.2199 31.8158 31.0999 35.0058 27.2599 35.0058H7.37992C3.53992 35.0058 0.419922 31.8158 0.419922 27.8858V7.54578C0.419922 3.61578 3.53992 0.425781 7.37992 0.425781V0.425781Z" stroke={color} strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.2992 19.8862C22.9592 19.6462 22.5492 19.5362 22.1792 19.3362C21.6692 19.0762 21.2992 18.9162 21.0292 18.8062C20.8592 18.7462 20.4692 18.5662 20.3292 18.6762C19.8992 19.0362 19.4392 20.0562 18.9492 20.2562C17.7292 20.0062 16.6092 19.1562 15.7292 18.2862C15.3292 17.8962 14.6192 16.8262 14.4592 16.5262C14.4292 16.2262 14.9592 15.8262 15.0692 15.5962C15.6792 14.8862 15.2192 14.4562 15.1392 14.1562C14.9992 13.8562 14.7692 13.3162 14.5592 12.8662C14.3892 12.5762 14.3492 12.1462 14.0292 11.9862C12.6892 11.2762 11.9292 12.6862 11.6092 13.4262C9.71922 18.0862 21.0992 26.9762 23.4092 20.8562C23.5292 20.3262 23.4792 20.1262 23.2992 19.8762V19.8862Z" stroke={color} strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
  }
}


class Phone extends Component {
  render() {
    let { color } = this.props
    return (
    <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.6407 36.1369C28.2307 36.1369 36.0607 28.3069 36.0607 18.7169C36.0607 9.12687 28.2307 1.29688 18.6407 1.29688C9.0507 1.29688 1.2207 9.11687 1.2207 18.7169C1.2207 28.3169 9.0507 36.1369 18.6407 36.1369Z" stroke={color} strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5902 22.757C12.7802 20.977 9.73023 17.197 9.41023 14.197C9.30023 13.167 9.55024 12.267 10.0902 11.497C10.6602 10.667 11.5602 9.97701 12.5002 9.60701C13.1602 9.35701 13.7402 9.38701 14.1402 10.247L15.9902 14.237C16.3502 15.017 15.4002 15.737 14.8502 16.507C14.5002 16.997 14.4502 17.127 14.7402 17.647C15.1202 18.327 15.9802 19.417 16.9702 20.407C17.9602 21.387 19.0502 22.257 19.7302 22.637C20.2502 22.927 20.3802 22.877 20.8702 22.527C21.6302 21.977 22.3602 21.027 23.1402 21.387L27.1302 23.237C27.9902 23.637 28.0202 24.217 27.7702 24.877C27.4102 25.817 26.7102 26.707 25.8802 27.287C25.1102 27.827 24.2102 28.077 23.1802 27.967C20.1702 27.647 16.4002 24.597 14.6202 22.787L14.5902 22.757Z" stroke={color} strokeWidth="0.6" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    )
  }
}

class Close extends Component {
  render() {
    return (      
      <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="0.353553" y1="0.649376" x2="20.3536" y2="20.6494" stroke="black"/>
        <line x1="20.3536" y1="1.35648" x2="0.353554" y2="21.3565" stroke="black"/>
      </svg>
    )
  }
}

class Search extends Component {
  render() {
    let { color } = this.props
    return (
      <svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1289.000000, -46.000000)">
            <g transform="translate(1289.000000, 46.000000)">
              <g>
                <g>
                  <path d="M18.6635459,17.284253 L11.6362006,10.9253654 C13.1367102,9.17733337 13.6202361,6.77412093 12.9112432,4.58183552 C12.2032082,2.38959923 10.405318,0.724091073 8.16612176,0.184001938 C5.92591848,-0.355178451 3.56664508,0.308723477 1.93778144,1.93778114 C0.308721319,3.56684127 -0.355178153,5.92594275 0.184002237,8.16612146 C0.724140492,10.4053669 2.38967321,12.2034044 4.58183582,12.9112429 C6.7740721,13.6202358 9.17738279,13.1367025 10.9253657,11.6362003 L17.9477988,18 L18.6635459,17.284253 Z M2.65141627,10.5820072 C1.59032164,9.52857551 0.991655311,8.09720422 0.987813382,6.60145942 C0.984945581,5.1067216 1.57592909,3.67137148 2.63223439,2.61329781 C3.68758182,1.55411892 5.12194951,0.959284049 6.61671189,0.959284049 C8.11147427,0.959284049 9.5458174,1.55411892 10.6011894,2.61329781 C11.6574947,3.67151884 12.2484735,5.10669704 12.2456104,6.60145942 C12.2417623,8.0971551 11.6431021,9.52860007 10.5820075,10.5820072 C9.5362633,11.6440597 8.10776561,12.2417682 6.61668733,12.2417682 C5.12560905,12.2417682 3.69716048,11.6440597 2.65136715,10.5820072 L2.65141627,10.5820072 Z" fill={color} fillRule="nonzero"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

class DropDown extends Component {
  render() {
    return (
      <svg width="8px" height="4px" viewBox="0 0 8 4" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-505.000000, -432.000000)" fill="#FFFFFF">
            <g transform="translate(300.000000, 346.000000)">
              <g transform="translate(205.000000, 86.000000)">
                <polygon transform="translate(4.000000, 2.000000) scale(1, -1) translate(-4.000000, -2.000000) " points="4 0 8 4 0 4"></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}


export { Logo, LogoLarge, LogoMark, Mail, WhatsApp, Phone, Close, Search, DropDown }
