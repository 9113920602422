import React, { useEffect, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { LogoLarge, LogoMark } from './icons'

import FadeInOut from "../utils/FadeInOut";

const useSessionModal = () => {
  const session = "landing";
  const [show, setShow] = useState(true);

  //const toggleShow = () => setShow(!show);

  const toggleShow = () => {
    const modalKey = "modalSession";
    sessionStorage.setItem(modalKey, session);
    setShow(!show);
  };

  useEffect(() => {
    const modalKey = "modalSession";
    const modalSession = sessionStorage.getItem(modalKey);
    setShow(modalSession !== session);
  
    if (show) {
      const timeout = setTimeout(() => toggleShow(), 4000);
      return () => {
        clearTimeout(timeout);
      };
    }
  });

  return [show, toggleShow];
};


export default function App(props) {

  // const [showModal, hideModal] = useSessionModal();  
  const [show, toggleShow] = useSessionModal();  

  return <FadeInOut show={show} duration={500}>
    <section id='landing'>
      <div onClick={toggleShow} className='landing__inner'>
        { props.data.wp.siteSettings.acf.landingImage &&
          <GatsbyImage image={getImage(props.data.wp.siteSettings.acf.landingImage?.localFile)} alt='London House' />
        }
        <div className='landing__logo'>
          <div className='desktop'>
            <LogoLarge color={'#F9F5EF'} />
          </div>

          <div className='mobile'>
            <LogoMark color={'#F9F5EF'} />
          </div>
        </div>
      </div>
    </section>
  </FadeInOut>
}

// import React, { useEffect, useState } from 'react'
// import { GatsbyImage, getImage } from 'gatsby-plugin-image'
// import { LogoLarge, LogoMark } from './icons'

// const useSessionModal = () => {
//   const session = "landing";
//   const [showModal, setShowModal] = useState(true);
  
//   const hideModal = () => {
//     const modalKey = "modalSession";
//     sessionStorage.setItem(modalKey, session);
//     setShowModal(false);
//   };
//   useEffect(() => {
//     const modalKey = "modalSession";
//     const modalSession = sessionStorage.getItem(modalKey);
//     setShowModal(modalSession !== session);

//     if (showModal) {
//       const timeout = setTimeout(() => hideModal(), 4000);
//       return () => {
//         clearTimeout(timeout);
//         // console.log('remove');
//       };
//     }

//   });
//   return [showModal, hideModal];
// };


// export default function App(props) {
//   const [showModal, hideModal] = useSessionModal();
//   //const { background } = this.props.data.wp.siteSettings.acf

//   return <section id='landing' className={showModal ? 'show' : 'hide'}>

//   <div onClick={hideModal} className='landing__inner'>
//     { props.data.wp.siteSettings.acf.landingImage &&
//       <GatsbyImage image={getImage(props.data.wp.siteSettings.acf.landingImage?.localFile)} alt='London House' />
//     }
//       <div className='landing__logo'>
//         <div className='desktop'>
//           <LogoLarge color={'#F9F5EF'} />
//         </div>

//         <div className='mobile'>
//           <LogoMark color={'#F9F5EF'} />
//         </div>
//       </div>
//   </div>
// </section>
// }