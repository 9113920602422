const universalClickEvent = (text, data = {}) => {
    if (typeof window !== 'undefined') {
        // Uncomment for development alerts
        // if (process.env.NODE_ENV === 'development') {
        //     alert(`Event Triggered: ${text}`);
        // }

        // Initialize dataLayer if not already initialized
        window.dataLayer = window.dataLayer || [];

        // Push the event to dataLayer
        window.dataLayer.push({
            event: text,
            ...data,
        });
    }
};

const footerLinkClick = () => {
    universalClickEvent('footer-link-click');
};

export {
    footerLinkClick,
};
