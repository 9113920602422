import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import CookieConsent, { Cookies } from "react-cookie-consent";
//import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import Link from '../utils/link'

import Header from '../components/header'
import Footer from '../components/footer'
import Landing from '../components/landing'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wp {
          siteSettings {
            acf {
              footerLogos {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1800
                      placeholder: NONE
                      backgroundColor: "transparent"
                    )
                  }
                }
              }
              landingImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1800
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Header data={data} />
          <Landing data={data} />
          <main>{ children }</main>
          <Footer data={data} />

          <CookieConsent
            style={{ background: "rgb(212, 168, 148, 0.98)" }}
            buttonStyle={{ color: "#000", background: "transparent", border:"1px solid", padding: "10px 30px", fontSize: "12px" }}
            declineButtonStyle={{ color: "#000", background: "transparent", border:"1px solid", padding: "10px 30px", fontSize: "12px" }}
            expires={150}
            flipButtons={false}
            enableDeclineButton
            onDecline={() => {
              Cookies.remove('_ga')
              Cookies.remove('_gat')
              Cookies.remove('_gid')
            }}
            onAccept={() => {
                initializeAndTrack(location)
            }}
            buttonWrapperClasses="button-container"
            location="bottom"
            declineButtonText="No thank, You"
            buttonText="Accept All"
            cookieName="gatsby-gdpr-google-analytics">
            <span>London House uses cookies. For more information click <Link to='/privacy-policy'>here.</Link></span>
          </CookieConsent>
        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
