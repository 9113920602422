import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo, LogoMark, Mail, WhatsApp, Phone } from '../components/icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  componentDidMount() {
    window && window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window && window.removeEventListener('scroll', this.scrollDetect)
  }

  toggleScrolled = () => {
    this.setState({ scrolled: !this.state.scrolled })
  }

  onScroll = (e) => {
    let scrolled = window.scrollY > 1
    if (scrolled !== this.state.scrolled) {
      requestAnimationFrame(this.toggleScrolled)
    }
  }

  scrollDetect = () => {
    window && window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' scrolled'

    return (
      <>
        <header className={headerClass}>
        <div className={`header__inner ${ offCanvas && 'off-canvas--active' }`}>
          {/* <div className='header__inner'> */}

            <div className='header__left'>
              <nav className='header__nav'>
                <ul>
                  <li><Link to='/buy' {...props}>Buy</Link></li>
                  <li><Link to='/sell' {...props}>Sell</Link></li>
                  <li><Link to='/rent' {...props}>Rent</Link></li>
                  <li><Link to='/let' {...props}>Let</Link></li>
                </ul>
              </nav>
            </div>

            <div className='header__center'>
              <Link to='/' title='London House' className='header__logo' {...props}>
                <Logo color={'#000'} />
              </Link>

              <div className='off-canvas__logo'>
                <Link to='/' title='London House' {...props}>
                  <LogoMark color={'#000'} />
                </Link>
              </div>
            </div>


            <div className='header__right'>
              <nav className='header__nav'>
                <ul>
                  <li><Link to='/about' {...props}>About</Link></li>
                  <li><Link to='/the-knowledge' {...props}>The Knowledge</Link></li>
                  <li><Link to='/contact' {...props}>Contact</Link></li>
                </ul>
              </nav>
            </div>

            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>

          </div>
        </header>

        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>

            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/buy' {...props}>Buy</Link></li>
                <li><Link to='/sell' {...props}>Sell</Link></li>
                <li><Link to='/rent' {...props}>Rent</Link></li>
                <li><Link to='/let' {...props}>Let</Link></li>
                <li><Link to='/about' {...props}>About</Link></li>
                <li><Link to='/the-knowledge' {...props}>The Knowledge</Link></li>
                <li><Link to='/contact' {...props}>Contact</Link></li>
              </ul>

              <ul className='off-canvas__social'>
                <li>
                  <Link to='mailto:hello@londonhouse.co.uk'>
                    <Mail color={'#000'} />
                  </Link>
                </li>
                <li>
                  <Link to='https://wa.me/+442074869665'>
                    <WhatsApp color={'#000'} />
                  </Link>
                </li>
                <li>
                  <Link to='tel:+442074869665'>
                    <Phone color={'#000'} />
                  </Link>
                </li>
              </ul>

            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header