import React, { Component } from 'react'
import Link from '../utils/link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import {footerLinkClick} from '../utils/analytics.js'

import { LogoMark } from './icons'

class Footer extends Component {
  render() {

    const { footerLogos } = this.props.data.wp.siteSettings.acf;
    
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__col'>
            <Link to='/' title='London House' className='footer__logo'>
              <LogoMark color={'#F9F5EF'} />
            </Link>

          </div>

          <div className='footer__col'>

          </div>

          <div className='footer__col'>
            {/* <ul>
              <li className='title'>CATEGORIES</li>
              <li><Link to='/apartments'>Apartments</Link></li>
              <li><Link to='/townhouses'>Townhouses</Link></li>
              <li><Link to='/mansions'>Mansions</Link></li>
              <li><Link to='/countryside'>Countryside</Link></li>
              <li><Link to='/developments'>Developments</Link></li>
              <li><Link to='/investments'>Investments</Link></li>
            </ul> */}

            <ul className='mobile'>
              <li className='title'>SERVICES</li>
              <li><Link to='/buy'>Buy</Link></li>
              <li><Link to='/sell'>Sell</Link></li>
              <li><Link to='/rent'>Rent</Link></li>
              <li><Link to='/let'>Let</Link></li>
            </ul>

            <ul className='mobile'>
              <li className='title'>ABOUT</li>
              <li><Link to='/about/#team'>Team</Link></li>
              <li><Link to='/contact'>Contact</Link></li>
              <li><Link to='/privacy-policy'>Privacy Policy<br/> & Cookies</Link></li>
            </ul>

          </div>

          <div className='footer__col'>
            <ul>
              <li className='title'>LONDON</li>
              <li><Link to='/buy?search=Belgravia'>Belgravia</Link></li>
              <li><Link to='/buy?search=Chelsea'>Chelsea</Link></li>
              <li><Link to='/buy?search=City'>City</Link></li>
              <li><Link to='/buy?search=Fitzrovia'>Fitzrovia</Link></li>
              <li><Link to='/buy?search=Knightsbridge'>Knightsbridge</Link></li>
              <li><Link to='/buy?search=Kensington'>Kensington</Link></li>
              <li><Link to='/buy?search=Hampstead'>Hampstead</Link></li>
              <li><Link to='/buy?search=Holland+Park'>Holland Park</Link></li>
              <li><Link to='/buy?search=Hyde+Park'>Hyde Park</Link></li>
              <li><Link to='/buy?search=Marylebone'>Marylebone</Link></li>
              <li><Link to='/buy?search=Mayfair'>Mayfair</Link></li>
              <li><Link to='/buy?search=St+James'>St. James</Link></li>
              <li><Link to='/buy?search=Regents+Park'>Regents Park</Link></li>
              <li><Link to='/buy?search=Totteridge'>Totteridge</Link></li>
              {/* <li><a href="http://localhost:8000/buy?search=Regents+Park" rel="noopener norefferer">Regents Park</a></li> */}
            </ul>
          </div>

          <div className='footer__col desktop'>
           <ul>
              <li className='title'>SERVICES</li>
              <li><Link to='/buy'>Buy</Link></li>
              <li><Link to='/sell'>Sell</Link></li>
              <li><Link to='/rent'>Rent</Link></li>
              <li><Link to='/let'>Let</Link></li>
            </ul>
          </div>

          <div className='footer__col desktop'>
            <ul>
              <li className='title'>ABOUT</li>
              <li><Link to='/about/#team'>Team</Link></li>
              <li><Link to='/contact'>Contact</Link></li>
              <li><Link to='/complaints-procedure'>Complaints</Link></li>
              <li><Link to='/privacy-policy'>Privacy Policy<br/> & Cookies</Link></li>
            </ul>

          </div>

          <div className='footer__col'>
            <ul>
              <li className='title'>FOLLOW</li>
              <li><Link to='https://www.instagram.com/londonhouseuk'>Instagram</Link></li>
              <li><Link to='https://www.tiktok.com/@londonhouseuk_'>TikTok</Link></li>
              <li><Link to='https://www.linkedin.com/company/londonhouseuk/about/'>LinkedIn</Link></li>
              <li><Link to='https://twitter.com/LondonHouseUK1'>X</Link></li>
            </ul>
          </div>

          <div className='footer__col'>
            <ul className='details'>
              <li className='title'>CONTACT</li>
              <li>
              65 Weymouth St<br/>
              London<br/>
              W1G 8NU
              </li>
              <li><Link to='tel:+44(0)2074869665'>+44 (0) 20 7486 9665</Link></li>
              <li><Link to='mailto:hello@londonhouse.co.uk'>hello@londonhouse.co.uk</Link></li>
              <li><br/></li>
              <li>
              London House U.K LTD<br/>
              Registered Office: 201<br/>
              Haverstock Hill London<br/>
              NW3 4QG
              </li>
              <li><br/></li>
              <li>
              Company Registration<br/>
              No: 08647220
              </li>
              <li><br/></li>
              <li>{new Date().getFullYear()} London House<br/>All rights reserved</li>
              <li>
                Site by <Link to='https://studiosmall.com' onClick={footerLinkClick}>Studio Small</Link>
              </li>
              <li>
              <div className='footer__complaints'>

              { footerLogos &&
                <GatsbyImage image={getImage(footerLogos?.localFile)} alt='complaints' />
              }
              </div>

              </li>
            </ul>
          </div>

        </div>
      </footer>
    )
  }
}

export default Footer
